
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.agreement {
  &-wrapper {
    margin: 15px 0;
    position: relative;
  }
  position: relative;
  z-index: 2;
  min-height: 80px;
  padding: 16px 24px;
  background: #FFFFFF;
  border: 1px solid #E8E8EE;
  border-radius: 16px;

  @include for-size(phone-portrait-down) {
    padding: 16px;
  }

  &-status {
    position: absolute;
    z-index: 1;
    top: 0;
    left: -8px;
    width: 24px;
    height: 76px;
    border-radius: 8px;
    background-color: #f5f5f5;

    &_blue {
      background-color: #2F9AFF;
    }

    &_gray {
      background-color: #737882;
    }

    &_purple {
      background-color: $color-client-portal-logo;
    }

    &_yellow {
      background-color: #FFB608;
    }

    &_green {
      background-color: #70DC9F;
    }

    &_red {
      background-color: #FF4747;
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include for-size(phone-portrait-down) {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: 4px;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    @include for-size(phone-portrait-down) {
      margin-top: 4px;
    }
  }
  &__info {
    &-item {
      margin: 6px 0 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      &:first-child {
        margin: 0;
      }
      &-label {
        width: 115px;
        color: #797E8B;
      }
    }
  }
  &__actions {
    margin-top: $base-gutter;
    display: flex;
    gap: $base-gutter;
  }
  &__action {
    display: block;

    &--right {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
.members {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;

  @include for-size(phone-portrait-down) {
    display: block;
  }
}
.member {
  $member: &;

  margin: 10px 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__status {
    margin-right: 8px;
    background: #fff;
    padding: 2px 2px 14px;
    border: 2px solid transparent;
    border-radius: 20px;
    position: relative;

    &--denied,
    &--cancelled-by-customer,
    &--cancelled-by-executor {
      border-color: $color-approve-red;

      #{$member}__status-icon-wrapper {
        background: $color-approve-red;
      }

      #{$member}__status-icon--close {
        display: block;
        color: $color-error-day;
      }
    }

    &--future,
    &--closed,
    &--pending {
      border-color: $color-grayscale-10;

      #{$member}__status-icon-wrapper {
        background: $color-grayscale-10;
      }
    }

    &--future,
    &--pending {
      #{$member}__status-icon--clock {
        display: block;
        color: $color-black-op-50;
      }
    }

    &--closed {
      #{$member}__status-icon--minus {
        display: block;
        color: $color-black-op-50;
      }
    }

    &--approved {
      border-color: $color-approve-green;

      #{$member}__status-icon-wrapper {
        background: $color-approve-green;
      }

      #{$member}__status-icon--check {
        display: block;
        color: $color-success-day;
      }
    }
  }

  &__status-icon-wrapper {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    border: 2px solid $color-white;
    padding: 3px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__status-icon {
    width: 100%;
    height: 100%;
    display: none;
  }

  &__info {

  }
  &__name {
    font-size: 12px;
    line-height: 16px;
  }
  &__email {
    font-size: 10px;
    line-height: 12px;
    color: #797E8B;
  }

  &__avatar {
    width: 24px;
    height: 24px;
  }
}
